ul{
    border: none;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .breakdownContainer{
    flex-basis: 50%;
    display: flex;
    flex-direction:column;
    padding: 0px 20px 0px 20px;
  }
  
  .breakdownContainer > tr:nth-child(2n-1) {
    filter: brightness(1.5);
  }

  .breakdownContainer > * {
    border:1px solid black;
    flex-grow:1;
  }

  .breakdownContainer tr {
    margin: 2px 0;
    display: flex;
    justify-content: center;
  }

  .breakdownContainer td:first-child {
    background-color: rgb(91, 91, 97);
  }

  .breakdownContainer td:nth-child(2) {
    background-color: #628395;
  }

  .breakdownContainer tr td {
    text-align: center;
    flex-basis: 50%;
  }

  .configDiv{
    display:flex;
    flex-direction:column;
    background-color:#948296;
    line-height: 50px;
    color: white;
    padding: 2rem;
  }

  .configDiv > div {
    text-align: center;
  }
  .configContent{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .fade{
    opacity:1;
  }
  .upperLevel
  {
    display: flex;
    flex-direction: column;
  }
  
  .highlighted{
    background:#FFFF9911;
    filter:brightness(95%);
    border:1 px solid #FFFFFF11 ;
    box-shadow:0 0 0 rgba(0,0,0,0);
  }
  
  .inlineLevel{
    display: flex;
    min-height: 70px;
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-block: 0.5rem;
  }
  
  .focusedLevel{
    border:1px solid #F1F1F1;
    filter:brightness(95%);
    box-shadow:13px 12px 15px 5px rgba(0,0,0,0.19);
  }
  .expandButton{
    width: 5%;
    margin-left: 10px;
  }
  
  .configName{
    width: 15%;
  }
  .inlineLevel>*{
    flex-grow: 1;
    text-align: center;
  }
  
  .inlineLevel:hover{
    background:#F1F1F1;
  }
  
  .upperLevel>*{
    display: flex;
    flex-direction: column;
  }
  
  .breakdownImageContainer{
    width:150px;
    height:150px;

  }
  
  .EditableTextField{
    display: flex;
    flex-direction:row;
    justify-content: center;
    gap: 30px;
    width: 100%;
  }
  
  .inlineTextField{
    display: flex;
    align-items:center;
    gap: 10px;
  
  }

  .textFieldHeader {
    font-weight: 500;
    margin-bottom: 5px;
  }

  .margin {
    margin-top: 5px;
  }

  button {
    border-radius: 3px;
    border: none;
    padding: 8px 12px;
    background-color: #948296;
    color: #fff;
    transition: 0.15s linear all
  }

  button:hover {
    background-color:#5d4560;
    cursor: pointer
  }


  .textType, .textName {
    text-transform: capitalize;
  }

  .infoButtonContainer {
    display:inline-block;
    border-radius: 50%;
    background-color: #948296;
    transition: 0.15s linear all;
  }

  .infoButtonContainer:hover {
    background-color: #5d4560;
    cursor: pointer;
  }

  .infoButtonContainer > p {
    margin: 5px 10px;
    color: #fff;
  }

  .infoButtonContainerInvis {
    opacity: 0;
  }

  @media (max-width: 792px) {
    ul.upperLevel, ul.inlineLevel {
      display: flex;
      flex-direction: column;
    }
  }